/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require('../controller/users')

// NOTE: jQuery only required here until bootstrap can be loaded by webpack
window.jQuery = require('jquery')

window.$ = window.jQuery

// Rails UJS
require('jquery-ujs')

const ClipboardJS = require('clipboard/dist/clipboard')

new ClipboardJS('.clipboard-btn')

