const jQuery = require('jquery')

const UsersController = jQuery($ => {
  // List of valid "tab targets" we can activate
  const tabs = $('[role=tablist] .nav-link')

  // Show the active tab by location, if any, otherwise the default
  const selectActiveOrDefaultTab = function (activeTab) {
    const tabTargets = tabs.map((_index, element) => $(element).attr('href'))

    // Activate tab control based on url hash or default to first tab
    return $.inArray(activeTab, tabTargets) >= 0 ?
      $(`[role=tablist] .nav-link[href='${activeTab}']`).tab('show') :
      $('[role=tablist] .nav-link:first').tab('show')
  }

  // Set the default tab on load
  selectActiveOrDefaultTab(window.location.hash)

  // Bind to `hashchange` so menu links work on settings page
  $(window).on('hashchange', () =>
    selectActiveOrDefaultTab(window.location.hash)
  )

  // Update the existing hash since BS4 doesn't update it on change by default
  return tabs.on('show.bs.tab', event => {
    const tab = $(event.target)
    window.location.hash = tab.attr('href')
  })
})

export default UsersController
